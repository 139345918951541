body {
  background-color: #f5f5f5;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: SemiBoldFont;
  src: url("./fonts/Poppins-SemiBold.ttf");
}
@font-face {
  font-family: MediumFont;
  src: url("./fonts/Poppins-Medium.ttf");
}
@font-face {
  font-family: RegularFont;
  src: url("./fonts/Poppins-Regular.ttf");
}

h1 {
  font-family: SemiBoldFont;
  color: #343434;
}

h2 {
  font-family: SemiBoldFont;
  color: #343434;
}

h3 {
  font-family: SemiBoldFont;
  color: #343434;
}

p {
  color: #343434;
  font-family: RegularFont;
  font-size: 14px;

  @media only screen and (min-width: 768px) {
    font-size: 20px;
  }
}

a {
  color: #da1710;
  text-decoration: none;
}

.error-message {
  color: #d5002b;
  font-size: 10px;
  margin-top: -5px;
  text-align: left;
}

.tooltip {
  cursor: pointer;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted #343434;
}

.tooltipsubtext {
  color: #dddddd;
  font-size: 10px;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #343434;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip:active .tooltiptext {
  visibility: visible;
}
